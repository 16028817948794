import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Rellax from 'rellax'

import PageHead from 'components/head/PageHead'
import AssetCountHeading from 'components/heading/AssetCountHeading'
import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import LogosTrezor from 'components/logos-trezor'
import buildImageObject from 'js/utils/buildImgObject'
import { ASSETS_PAGE_PATH, SUPPORT_URL, TREZOR_URL, isUk } from 'src/constants'

// Styles & Images
import 'static/trezor/scss/styles.scss'

// Helpers
const TREZOR_BRIDGE_URL = 'https://suite.trezor.io/web/bridge'
const TREZOR_MODELT_URL = 'https://shop.trezor.io/product/trezor-model-t'
const CUSTOM_INDICATOR_COUNT = isUk ? 2 : 3

const CustomIndicator1 = () => (
  <Carousel.Caption>
    <div className="x-header-carousel__desc x-carousel-indicators-dark">
      <span className="x-header-carousel__desc-numbers">1/{CUSTOM_INDICATOR_COUNT}</span>
      <span className="x-header-carousel__desc-title">Trezor Portfolio</span>
      <span className="x-header-carousel__desc-txt">
        Track your Trezor portfolio performance with real time charts and live market details.
      </span>
    </div>
  </Carousel.Caption>
)

const CustomIndicator2 = () => (
  <Carousel.Caption>
    <div className="x-header-carousel__desc x-carousel-indicators-dark">
      <span className="x-header-carousel__desc-numbers">2/{CUSTOM_INDICATOR_COUNT}</span>
      <span className="x-header-carousel__desc-title">Trezor Multi-Asset Wallet</span>
      <span className="x-header-carousel__desc-txt">
        Securely and easily manage over 90 Trezor supported assets.
      </span>
    </div>
  </Carousel.Caption>
)

const CustomIndicator3 = () => (
  <Carousel.Caption>
    <div className="x-header-carousel__desc x-carousel-indicators-dark">
      <span className="x-header-carousel__desc-numbers">3/{CUSTOM_INDICATOR_COUNT}</span>
      <span className="x-header-carousel__desc-title">Hardware Wallet Swap</span>
      <span className="x-header-carousel__desc-txt">
        Swiftly swap assets without ever giving up control of your private keys.
      </span>
    </div>
  </Carousel.Caption>
)

class TrezorPage extends React.PureComponent {
  componentDidMount() {
    // Setup parallax scrolling
    Rellax('.rellax', {
      center: true,
    })
  }

  render() {
    return (
      <PageWrapper className="x-page-trezor" backgroundColor="rgb(26, 28, 31)">
        <header className="x__header">
          <div className="x-header__darken" id="x-header-darken" role="presentation" />
          <div className="x-header__x rellax" data-rellax-speed="-8" role="presentation" />
          <div className="x__header-bg-container rellax" data-rellax-speed="-4" role="presentation">
            <div className="x__header-bg">
              <Img
                fluid={buildImageObject(images.headerbg)}
                durationFadeIn={2000}
                alt="Exodus empowers people to control their wealth"
              />
            </div>
          </div>
          <div className="x__header-content">
            <div className="x__header-txt-container">
              <span className="x__header-subheading">ADVANCED SECURITY MADE EASY</span>
              <Heading page="trezor" className="x__header-heading" />
            </div>

            <div className="x-header__buttons">
              <Link
                to="/download/?utm_source=trezor-wallet&utm_campaign=seo-landing"
                className="x__button"
              >
                <span className="x__button-align">
                  <span className="x__button-align-text">Download Wallet</span>
                </span>
              </Link>
            </div>

            <div className="x-header-carousel">
              <Carousel
                id="x-header-carousel"
                indicators={false}
                interval={7000}
                prevIcon={
                  <span
                    className="x-header-carousel__controls-left"
                    title="Previous"
                    href="#x-header-carousel"
                    role="button"
                    data-slide="prev"
                  >
                    <svg version="1.1" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
                      <title>Previous</title>
                      <polyline
                        transform="translate(5 7) scale(-1 1) rotate(-90) translate(-5 -7)"
                        points="11 4 5 10 -1 4"
                        stroke="#fff"
                        fill="none"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                }
                nextIcon={
                  <span
                    className="x-header-carousel__controls-right"
                    title="Next"
                    href="#x-header-carousel"
                    role="button"
                    data-slide="next"
                  >
                    <svg version="1.1" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
                      <title>Next</title>
                      <polyline
                        transform="translate(4 7) rotate(-90) translate(-4 -7)"
                        points="10 4 4 10 -2 4"
                        stroke="#fff"
                        strokeWidth="2"
                        fill="none"
                      />
                    </svg>
                  </span>
                }
              >
                <Carousel.Item>
                  <div className="x-header-carousel__content">
                    <Img
                      fluid={buildImageObject(images.carouselImg1)}
                      durationFadeIn={1000}
                      alt="Portfolio"
                    />
                  </div>
                  <CustomIndicator1 />
                </Carousel.Item>
                <Carousel.Item>
                  <div className="x-header-carousel__content">
                    <Img
                      fluid={buildImageObject(images.carouselImg2)}
                      durationFadeIn={1000}
                      alt="Wallet"
                    />
                  </div>
                  <CustomIndicator2 />
                </Carousel.Item>
                {!isUk && (
                  <Carousel.Item>
                    <div className="x-header-carousel__content">
                      <Img
                        fluid={buildImageObject(images.carouselImg3)}
                        durationFadeIn={1000}
                        alt="Swap"
                      />
                    </div>
                    <CustomIndicator3 />
                  </Carousel.Item>
                )}
              </Carousel>
            </div>
          </div>
        </header>

        <section className="x-trezor x__width">
          <div className="x-trezor__col1">
            <svg
              className="x-trezor__logo"
              role="presentation"
              version="1.1"
              viewBox="0 0 27 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <path
                  d="m23.05 12.288h3.9496v21.295h-0.027168l-13.472 6.4167-13.473-6.4167h-0.027168v-21.295h3.9515v-2.9897c0-5.1266 4.285-9.2984 9.5508-9.2984 5.263 0 9.548 4.1718 9.548 9.2984v2.9897zm-1.4839 17.754v-12.757h-16.133v12.757l8.0669 3.8328 8.066-3.8328zm-12.716-20.743v2.9897h9.3017v-2.9897c0-2.3728-2.0872-4.3035-4.6494-4.3035-2.565 0-4.6522 1.9307-4.6522 4.3035z"
                  fill="#fff"
                  fillRule="nonzero"
                />
              </g>
            </svg>

            <Heading type="h2" page="trezor" section="models" className="x-h2 x-h2--light" />

            <p className="x-p x-p--light">
              Manage {isUk ? '' : 'and swap'} your cryptocurrencies in Exodus securely from your
              Trezor. No signup or registration required.
            </p>

            <a
              href={TREZOR_URL}
              className="x__button x__button--top-margin x__button--trezor"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="x__button-align">
                <span className="x__button-align-text">Get your trezor now</span>
              </span>
            </a>
          </div>

          <div className="x-trezor__col2">
            <div className="x-trezor__model x-trezor__modelT">
              <span className="x-trezor__model-name">Trezor Model T</span>
              <span className="x-trezor__modelT-img" />
            </div>

            <div className="x-trezor__model x-trezor__modelOne-chrome" />

            <div className="x-trezor__model x-trezor__modelOne">
              <span className="x-trezor__model-name">Trezor One</span>
              <span className="x-trezor__modelOne-img" />
            </div>

            <a
              href={TREZOR_URL}
              className="x__button x__button--top-margin x__button--trezor-mobile"
            >
              <span className="x__button-align">
                <span className="x__button-align-text">Get your trezor now</span>
              </span>
            </a>
          </div>
        </section>

        <section className="x__width x-trezor-steps-container">
          <span className="x-trezor-steps-container__subheading">
            3 Simple Steps to Get Started <br /> with a brand new Trezor Model T
          </span>

          <div className="x-trezor-steps">
            <div className="x-trezor-steps__item">
              <span className="x-trezor-steps__title">Get your Trezor</span>
              <span className="x-trezor-steps__txt">
                <a href={TREZOR_MODELT_URL} target="_blank" rel="noreferrer noopener">
                  Get a Trezor
                </a>{' '}
                to start using your cryptocurrencies in Exodus securely from your hardware wallet.
              </span>
            </div>

            <div className="x-trezor-steps__item">
              <span className="x-trezor-steps__title">Install Trezor Bridge</span>
              <span className="x-trezor-steps__txt">
                Install{' '}
                <a href={TREZOR_BRIDGE_URL} target="_blank" rel="noreferrer noopener">
                  Trezor Bridge
                </a>{' '}
                to set up your brand new Trezor Model T with Exodus.
              </span>
            </div>

            <div className="x-trezor-steps__item">
              <span className="x-trezor-steps__title">Pair with Exodus</span>
              <span className="x-trezor-steps__txt">
                Open Exodus wallet with your Trezor plugged in to connect and start using them
                together!
              </span>
            </div>
          </div>

          {!isUk && (
            <>
              <a
                href={`${SUPPORT_URL}/article/1114-getting-started-with-exodus-and-trezor`}
                className="x__button x__button--trezor"
              >
                <span className="x__button-align">
                  <span className="x__button-align-text">Learn More</span>
                </span>
              </a>
              <span className="x-trezor-steps-info">
                For more information and help on using Trezor with Exodus, please visit our support
                page.
              </span>
            </>
          )}
        </section>

        <section className="x-ex-trezor">
          <div className="x-ex-trezor__content">
            <svg version="1.1" viewBox="0 0 75 24" xmlns="http://www.w3.org/2000/svg">
              <title>Exodus + Trezor</title>
              <path
                d="M21.8683 7.14273L12.4997 1V4.43443L18.5097 8.33987L17.8026 10.5772H12.4997V13.4228H17.8026L18.5097 15.6601L12.4997 19.5656V23L21.8683 16.8769L20.3363 12.0098L21.8683 7.14273Z"
                fill="#fff"
              />
              <path
                d="M4.34876 13.4228H9.63211V10.5772H4.32912L3.64169 8.33987L9.63211 4.43443V1L0.263489 7.14273L1.79546 12.0098L0.263489 16.8769L9.65175 23V19.5656L3.64169 15.6601L4.34876 13.4228Z"
                fill="#fff"
              />
              <path d="m39 11v-4h1v4h4v1h-4v4h-1v-4h-4v-1h4z" fill="#D8D8D8" opacity=".2" />
              <path
                d="M72.659 7.37278H74.9995V20.1498H74.9834L66.9998 23.9998L59.0156 20.1498H58.9995V7.37278H61.3411V5.57898C61.3411 2.50298 63.8804 -0.00012207 67.0009 -0.00012207C70.1197 -0.00012207 72.659 2.50298 72.659 5.57898V7.37278H72.659ZM71.7797 18.0248V10.3706H62.2194V18.0248L66.9998 20.3245L71.7796 18.0248H71.7797ZM64.2441 5.57878V7.37258H69.7562V5.57878C69.7562 4.15508 68.5192 2.99668 67.0009 2.99668C65.4809 2.99668 64.2441 4.15508 64.2441 5.57878Z"
                fill="#fff"
              />
            </svg>
            Exodus and Trezor worked in partnership to advance the{' '}
            <strong>crypto hardware wallet</strong> experience.
          </div>
        </section>

        <section className="x-features x__width">
          <div className="x-features__col">
            <span className="x-features__subheading">A secure partnership</span>
            <Heading type="h2" page="trezor" section="partnership" className="x-h2 x-h2--light" />
            <p className="x-p x-p--light">
              The security of Trezor Model T & Trezor One meets the intuitive design of Exodus
            </p>
          </div>
          <div className="x-features__col">
            <div className="x-features__box x-features__box--safety">
              <h3>Built-in Trezor security</h3>
            </div>
            <div className="x-features__box x-features__box--exchange">
              <h3>{isUk ? 'Transfer' : 'Swap'} assets between Trezor and Exodus</h3>
            </div>
          </div>
          <div className="x-features__col">
            <div className="x-features__box x-features__box--send">
              <h3>Assets stay securely offline on Trezor hardware</h3>
            </div>
          </div>
          <div className="x-features__col x-features__box x-features__box--advanced">
            <h3>Plug and play with all existing Trezors</h3>
          </div>
          <div className="x-features__col x-features__box x-features__box--support">
            <h3>Personal support 24/7 from the Exodus team</h3>
          </div>
          <div className="x-features__col x-features__box x-features__box--uf">
            <h3>Manage multiple Trezor devices simultaneously</h3>
          </div>
        </section>

        <section className="x-assets-supported x__width">
          <AssetCountHeading page="trezor" wallet="trezor" />
          {!isUk && <LogosTrezor />}

          <a
            data-toggle="collapse"
            href={ASSETS_PAGE_PATH}
            role="button"
            aria-expanded="false"
            aria-controls="showAll"
            className="x__button"
            id="x-assets-supported-btn"
          >
            <span className="x__button-align">
              <span className="x__button-align-text">See the full list</span>
            </span>
          </a>
        </section>

        <section className="x-team x__width">
          <span className="x-team__subheading">Beautiful Software For Secure Hardware</span>
          <span className="x-team__txt">
            Exodus helps individuals control their wealth independent of old banking models and free
            from institutional control. Now, Exodus & Trezor make advanced security easy - allowing
            anyone to safely manage digital assets.
          </span>
        </section>
      </PageWrapper>
    )
  }
}

export default TrezorPage

// <head> component:
export function Head() {
  return <PageHead page="trezor" />
}

const images = {
  headerbg: {
    aspectRatio: 1.8,
    path: '/trezor/img/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1536,
      },
      {
        fileName: 'header-bg-lsize',
        width: 3200,
      },
    ],
  },
  carouselImg1: {
    aspectRatio: 1.4846538187,
    path: '/trezor/img/carousel/',
    fileType: 'png',
    files: [
      {
        fileName: 'image01',
        width: 2080,
      },
    ],
  },
  carouselImg2: {
    aspectRatio: 1.4846538187,
    path: '/trezor/img/carousel/',
    fileType: 'png',
    files: [
      {
        fileName: 'image02',
        width: 2080,
      },
    ],
  },
  carouselImg3: {
    aspectRatio: 1.4846538187,
    path: '/trezor/img/carousel/',
    fileType: 'png',
    files: [
      {
        fileName: 'image03',
        width: 2080,
      },
    ],
  },
}
